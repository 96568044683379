(function ($) {
  $(document).ready(function () {
    function add_availabilities_to_cart(availabilities, master_overlay) {
      if (!availabilities.length) {
        $.ajax({
          url: theme.rest_url + "fccmaisonneuve/woocommerce/notice",
          data: {},
          type: "GET"
        }).fail(function (e) {
          console.log(e);
        }).done(function (response, textStatus) {
          if ("success" === textStatus) {
            //we hide the content not needed after adding
            master_overlay.find(".popup-selection__inner").css("display", "none");
            master_overlay.find(".btn-cart__modal").css("display", "none");
            //uncheck all boxes
            master_overlay.find(".availability-block-wrapper.selectable").each(function () {
              $(this).find(".availability-block__checkbox").attr("checked", false);
              $(this).removeClass("selected");
            });
            if (-1 !== response.indexOf("woocommerce-message")) {
              //we have at least 1 success in add to cart
              var cart_item = $("a.cart-link");
              if (cart_item.hasClass("empty")) {
                cart_item.removeClass("empty");
              }
              if (!cart_item.hasClass("has-items")) {
                cart_item.addClass("has-items");
              }
            }
            //the success notice already contains the link to the cart
            master_overlay.find(".popup__close-wrapper").after(response);
            master_overlay.find(".btn-close").css("display", "block");
            master_overlay.find(".btn-view-cart").css("display", "block");
            //remove the loader
            $(".facetwp-overlay").remove();
          }
        });
      } else {
        var ticket_id = availabilities.shift();
        if (ticket_id) {
          $.ajax({
            url: theme.rest_url + "fccmaisonneuve/woocommerce/add_ticket_to_cart",
            data: {
              product_id: ticket_id
            },
            type: "POST"
          }).fail(function (e) {
            console.log(e);
          }).done(function () {
            add_availabilities_to_cart(availabilities, master_overlay);
          });
        } else {
          add_availabilities_to_cart(availabilities, master_overlay);
        }
      }
    }
    $(document.body).on("click", ".popup-close", function () {
      //remove all woocommerce notices
      $(".popup-selection .woocommerce").remove();

      //all the hidden content reappears
      $(".popup-selection__inner").css("display", "flex");
      $(".btn-cart__modal").css("display", "block");

      //hide all the keep shopping button
      $(".btn-close").css("display", "none");
      $(".btn-view-cart").css("display", "none");
      //we close all popups
      $(".popup-overlay").css("display", "none");
      $("body").css({
        height: "auto",
        overflow: "visible"
      });
    });
    $(document.body).on("click", ".popup-omnivox-close", function () {
      $(".omnivox-modal").css("display", "none");
    });
    var closeButton = document.querySelector(".popup-close");
    if (closeButton) {
      closeButton.addEventListener("keyup", function (event) {
        if (event.keyCode === 13) {
          closeButton.click();
        }
      });
    }
    $(document.body).on("click", ".listing__product-item__btn-cart", function () {
      var popup_window = $(".popup-overlay[data-formationid=" + $(this).data("formationid") + "]");
      //do we have a popup to show ?
      if (popup_window.length) {
        popup_window.css("display", "block");
        $("body").css({
          height: "100%",
          overflow: "hidden"
        });
      }
    });
    $(document.body).on("click", ".btn-cart__modal", function () {
      $(".popup-selection__no-selection").css("display", "none");
      $(document.body).prepend('<div class="facetwp-overlay">');
      var availabilities_ids = [];
      $(this).parent().parent().find(".availability-block__checkbox").each(function () {
        if ($(this).attr("checked")) {
          availabilities_ids.push($(this).data("ticketid"));
        }
      });
      if (availabilities_ids.length) {
        add_availabilities_to_cart(availabilities_ids, $(this).parent().parent());
      } else {
        $(".popup-selection__no-selection").css("display", "block").focus();
        $(".facetwp-overlay").remove();
      }
    });
  });
})(jQuery); // Fully reference jQuery after this point.