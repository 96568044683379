(function ($) {
  $(document).ready(function () {
    $(document.body).on("click", ".availability-block-wrapper.selectable, .btn-cart.availabilities__btn-cart", function (target) {
      var popupSelection;
      var btnAjouterPanier;
      var nbSelected;
      if ($(this).closest(".popup-selection").length) {
        popupSelection = $(this).closest(".popup-selection");
      } else {
        popupSelection = $(this).closest(".availabilities__inner");
      }
      if (popupSelection.find(".btn-cart.btn-cart__modal").length) {
        btnAjouterPanier = popupSelection.find(".btn-cart.btn-cart__modal");
      } else {
        btnAjouterPanier = popupSelection.find(".btn-cart.availabilities__btn-cart");
      }
      var availabilityBlock = $(this);
      var input = availabilityBlock.find(".availability-block__checkbox");
      if (input.attr("checked")) {
        input.attr("checked", false);
        availabilityBlock.removeClass("selected");
      } else {
        input.attr("checked", true);
        availabilityBlock.addClass("selected");
      }
      if (target.currentTarget.classList.contains("btn-cart") && target.currentTarget.classList.contains("availabilities__btn-cart")) {
        nbSelected = 0;
      } else {
        nbSelected = $(".availability-block-wrapper.selectable.selected").length;
      }
      if (nbSelected > 0) {
        btnAjouterPanier.attr("style", "");
      } else {
        btnAjouterPanier.attr("style", "pointer-events: none; opacity: 0.5;");
      }
    });
  });
})(jQuery); // Fully reference jQuery after this point.